/**
 * @generated SignedSource<<83b4fa3b186f72b0e4640e59f9291ced>>
 * @relayHash b1343fac837a2225f5594d7a069ee878
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-misc/479.0.0-2024-09-12T15:07:41.922Z/shopLocalConfigQuery

import { ConcreteRequest, Query } from 'relay-runtime';
export type shopLocalConfigQuery$variables = {
  fetchUser: boolean;
  userId: string;
};
export type shopLocalConfigQuery$data = {
  readonly viewer: {
    readonly shopLocalNotificationFeatureFlag: boolean | null;
    readonly user?: {
      readonly hasUsedAppInLastDays: boolean | null;
    } | null;
  };
};
export type shopLocalConfigQuery = {
  response: shopLocalConfigQuery$data;
  variables: shopLocalConfigQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fetchUser"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v2 = {
  "alias": "shopLocalNotificationFeatureFlag",
  "args": [
    {
      "kind": "Literal",
      "name": "feature",
      "value": "shopLocalNotification"
    }
  ],
  "kind": "ScalarField",
  "name": "featureFlag",
  "storageKey": "featureFlag(feature:\"shopLocalNotification\")"
},
v3 = [
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
],
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "days",
      "value": 90
    }
  ],
  "kind": "ScalarField",
  "name": "hasUsedAppInLastDays",
  "storageKey": "hasUsedAppInLastDays(days:90)"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "shopLocalConfigQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "condition": "fetchUser",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "shopLocalConfigQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "condition": "fetchUser",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ]
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-misc/479.0.0-2024-09-12T15:07:41.922Z/shopLocalConfigQuery",
    "metadata": {},
    "name": "shopLocalConfigQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "3d7db75ad43dd4ee7e7b46b492369907";

export default node;
